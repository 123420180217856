<template>
  <div class="page-wrapper">
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>风险检测</el-breadcrumb-item>
          <el-breadcrumb-item>开始检测</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">开始检测</template>
      </main-header>
      <diqu></diqu>
    </div>
    <project-set
      :projectData="projectData"
      :projectId="projectId"
      @selectPro="selectPro"
      :city="city"
      :uuid="uuid"
      @getNewList="getNewList"
    ></project-set>
    <div class="section-box">
      <div>
        <div class="title title-box">报告信息</div>
        <div class="pickers">
          <div class="pickers-flex">
            <!-- <div class="item pickers-flex-left">
              <div class="label">检测版本：</div>
              <div>综合体检</div>
            </div> -->
            <div class="item">
              <div class="label">检测类型：</div>
              <div>年度检测</div>
            </div>
          </div>
          <div class="item">
            <div class="label">检测版本：</div>
            <div class="picker">
              <el-select v-model="versionRadio" placeholder="请选择">
                <el-option
                  v-for="item in getTaxFirmEditionListData"
                  :key="item.edition"
                  :label="item.name"
                  :value="item.edition"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="item">
            <!-- <i class="el-icon-warning icon-zq"></i> -->
            <div class="label">征期选择：</div>
            <template v-if="tableType.value != ''">
              <div class="picker">
                <template>
                  <el-select v-model="yearPicker.value" placeholder="请选择年">
                    <el-option
                      v-for="item in yearPicker.options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </template>
              </div>
            </template>
          </div>
          <div class="item">
            <el-tooltip
              class="item"
              effect="dark"
              placement="top-start"
              :open-delay="300"
            >
              <div slot="content">
                本系统基于您的授权获取数据，并以此为基础生成风险检测报告。您可以选择数据保留期限，包括保留7天、保留14天、保留30天或不保留（您需要注意的是如果选择不保留数据将无法查看发票数据和申报表数据）
              </div>
              <i class="el-icon-warning-outline icon-tip"></i>
            </el-tooltip>
            <div class="label">数据保留天数：</div>
            <div class="picker">
              <el-select
                v-model="deleteDataType"
                placeholder="请选择"
                filterable
                class="input"
              >
                <el-option
                  v-for="item in deleteDataTypeData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="item">
            <el-tooltip
              class="item"
              effect="dark"
              placement="top-start"
              :open-delay="300"
            >
              <div slot="content">
                出口企业：增值税税负率=（当期销项-当期进项+进项转出+免抵退出口销售×适用税率+简易征收应纳税额）÷（适用税率销售+简易计税销售+免抵退出口销售）数据来源企业增值税申报表<br />非出口企业：增值税税负率=应纳税额÷适用税率销售收入
              </div>
              <i class="el-icon-warning-outline icon-tip"></i>
            </el-tooltip>
            <div class="label">是否进出口企业：</div>
            <div class="picker">
              <el-select v-model="taxRateCalculation" placeholder="请选择">
                <el-option
                  v-for="item in ratioTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div class="form-bottom">
        <div class="form-bottom-item">
          <el-checkbox v-model="agree">
            <div class="text">我已阅读并同意</div>
          </el-checkbox>

          <div class="toc" @click="openLicense">《授权协议》</div>
        </div>
        <!-- <div class="form-bottom-item">
              <el-checkbox v-model="form.deleteDataType">底稿数据保留14天</el-checkbox>
            </div> -->
      </div>
      <sa-footer>
        <div class="btn-primary new-next-btn ease" @click="projectNext">下一步</div>
      </sa-footer>
    </div>
    <licen-dialog ref="opendialog"></licen-dialog>
    <areaNotFind ref="areaNotFindRef"></areaNotFind>

  </div>
</template>

<script>
import Vue from "vue";
import SectionTitle from "@/components/SectionTitle";
import QuanterPicker from "@/components/QuanterPicker";
import BreadCrumb from "@/components/BreadCrumb";
import Company from "./components/Company";
import projectSet from "./components/projectSet";
import MainHeader from "@/pages/components/MainHeader";
import ProgressDiagram from "@/pages/components/ProgressDiagram";
import Footer from "./components/Footer";
import { getHighestVersionPurchased } from "@/utils/helper";
import { editProjects, getRegion, getTaxFirmEditionList } from "@/api/api";
import areaNotFind from "./components/areaNotFind";

import licenDialog from "@/pages/Test/UploadTable/components/licen-dialog";
import Saalert from "@/plugins/saalert";
Vue.use(Saalert);

import diqu from "./components/diqu";

import {
  confirmUploadedTable,
  genTestReport,
  showModules,
  getVersion,
  useAuthorize,
  getProjectList,
  getProjectListNew,
  getAreaMsg,
  getCompanyMsgByTaxNo,
} from "@/api/api";
import { mapMutations, mapState } from "vuex";
import { zq2obj } from "@/utils/helper";

import { cloneDeep } from "lodash";

export default {
  components: {
    BreadCrumb,
    SectionTitle,
    QuanterPicker,
    Company,
    MainHeader,
    ProgressDiagram,
    SaFooter: Footer,
    projectSet,
    licenDialog,
    diqu,areaNotFind
  },
  props: {
    bankId: String,
    productID: [String, Number],
    productName: String,
    zqLocation: [String, Number],
    uuid: [String, Number],
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    city: function () {
      if (!this.areaTree) {
        return [];
      }
      const ret = cloneDeep(this.areaTree);
      function walk(data) {
        data.value = data.code;
        data.label = data.name;
        if (data.childNode && data.childNode.length > 0) {
          data.children = data.childNode;
          for (const child of data.children) {
            walk(child);
          }
        }
        delete data.childNode;
      }
      for (const areaTree of ret) {
        walk(areaTree);
      }
      return [...ret];
    },
  },
  data() {
    return {
      agree: false,
      deleteDataType: 14,
      deleteDataTypeData: [
        {
          label: "7天",
          value: 7,
        },
        {
          label: "14天",
          value: 14,
        },
        {
          label: "30天",
          value: 30,
        },
        {
          label: "不保留",
          value: 0,
        },
      ],
      // 20221012新增参数
      ratioTypeList: [
        {
          label: "是",
          value: 2,
        },
        {
          label: "否",
          value: 1,
        },
      ],
      taxRateCalculation: "",
      getTaxFirmEditionListValue: "",
      getTaxFirmEditionListData: "",
      // 20221012新增参数
      thirdDataSource: "",
      // 选择已有项目：1 创建新项目：2
      selectvalueIndex: "1",
      // 默认选中项目
      selectvalue: "",
      areaTree: [],

      projectId: null,
      projectData: null,
      userNum: 0,
      zqs: [],
      versionRadio: "",
      tableType: {
        options: [
          {
            value: 4,
            label: "年度报表",
          },
        ],
        value: null,
      },
      yearPicker: {
        options: [
          {
            value: "2023",
            label: "2023年",
          },
          {
            value: "2022",
            label: "2022年",
          },
          {
            value: "2021",
            label: "2021年",
          },
          {
            value: "2020",
            label: "2020年",
          },
          {
            value: "2019",
            label: "2019年",
          },
          {
            value: "2018",
            label: "2018年",
          },
        ],
        value: null,
      },
      tableCards: [
        {
          total: 0,
          uploaded: 0,
          title: "",
          productID: this.productID,
          zq: null,
        },
        {
          total: 0,
          uploaded: 0,
          title: "",
          productID: this.productID,
          zq: null,
        },
      ],
    };
  },
  watch: {
    ["yearPicker.value"]: async function (val) {
      await this.onYearPickerChanged(val);
    },
  },
  mounted() {
    this.getTaxFirm();
    localStorage.removeItem("taxSiteUsername");
    sessionStorage.removeItem("manualNsrsbh");
    sessionStorage.removeItem("isShowBase");

    // 手动自动
    sessionStorage.setItem("method", 1);

    this.getprojectList();
    this.getRegionData();

    //初始化显示为本年年度报表
    const current = new Date();
    this.tableType.value = 4;
    // 初始年份
    // this.yearPicker.value = current.getFullYear().toString(); //2022
    this.yearPicker.value = "2022"; //2022
    localStorage.setItem("zqY", this.yearPicker.value);

    //清楚store中的选择税数据信息
    this.$store.commit("UploadTable/setSDSSelection", {
      SDSlist: null,
    });
  },
  methods: {
    openLicense() {
      this.$refs.opendialog.openDialog();
    },
    getTaxFirm() {
      getTaxFirmEditionList().then((res) => {
        this.getTaxFirmEditionListData = res.data;
        if (res.data && res.data.length > 0) {
          this.versionRadio = res.data[0].edition;
        }
      });
    },
    projectNext() {
      if (!this.selectvalue) {
        this.$confirm(`请先选择项目！`, "温馨提示", {
          distinguishCancelAndClose: true,
          confirmButtonText: "确定",
          showCancelButton: false,
        })
          .then(() => {})
          .catch(() => {});
        return;
      }
      // 判断是否是正确的税号
      // 判断是否对接税局

      if (!this.versionRadio) {
        this.$confirm(`请选择检测版本！`, "温馨提示", {
          distinguishCancelAndClose: true,
          confirmButtonText: "确定",
          showCancelButton: false,
        })
          .then(() => {})
          .catch(() => {});
        return;
      }

      if (!this.taxRateCalculation) {
        this.$confirm(`请选择是否进出口企业！`, "温馨提示", {
          distinguishCancelAndClose: true,
          confirmButtonText: "确定",
          showCancelButton: false,
        })
          .then(() => {})
          .catch(() => {});
        return;
      }
      if (!this.agree) {
        this.$confirm(`请阅读并同意授权协议！`, "温馨提示", {
          distinguishCancelAndClose: true,
          confirmButtonText: "确定",
          showCancelButton: false,
        })
          .then(() => {})
          .catch(() => {});
        return;
      }

      // 提示当前征期
      let typeName = this.zqs[0].slice(0, 4) + "年";
      this.$confirm("当前选中的征期为" + typeName, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "重新选择",
        type: "warning",
      })
        .then(() => {
          this.isRealTaxNo();
        })
        .catch(() => {});
    },
    isRealTaxNo() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getCompanyMsgByTaxNo(this.selectvalue.consumerEntity.nsrsbh, {}, 0).then((res) => {
        if (res.msgCode == 1) {
          getAreaMsg(this.selectvalue.consumerEntity.nsrsbh).then((res2) => {
            this.loading.close();
            if (res2.msgCode == 1) {
              if(!res2.data.available){
                this.$refs['areaNotFindRef'].dialogVisible=true
                return; 
              }

              this.available = res2.data.available;
              this.thirdDataSource = res2.data.thirdDataSource;
              this.toUploadNextTo();
            } else {
              this.$message({
                message: res2.msgInfo,
                type: "warning",
              });
            }
          });
        } else {
          this.loading.close();
          this.$confirm("客户纳税人识别号有误，请重新创建客户?", "提示", {
            confirmButtonText: "创建客户",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$router.push({ name: "custom", query: { create: 1 } });
            })
            .catch(() => {});
        }
      });
    },
    getRegionData() {
      getRegion().then((res) => {
        this.areaTree = res.data;
      });
    },
    toUploadNextTo() {
      this.toNextAuto();
      // 更新项目信息
      // if (this.selectvalueIndex == 1) {
      //   let params = {
      //     id: this.selectvalue.id,
      //     uuid: this.uuid,
      //     nsrsbh: this.selectvalue.nsrsbh,
      //   };
      //   editProjects(params, localStorage.getItem("authorization")).then((res) => {
      //     if (res.msgCode == 1) {
      //       this.toNextAuto();
      //     } else {
      //       this.$message({
      //         message: res.msgInfo,
      //         type: "warning",
      //       });
      //     }
      //   });
      //   return;
      //   // 更新项目信息
      // } else {
      //   this.toNextAuto();
      // }
    },

    toNextAuto() {
      let dataPeriod = "";
      if (this.available) {
        dataPeriod = 3;
      } else {
        dataPeriod = 0;
      }
      this.$router.push({
        name: "upload-table",
        params: {
          id: this.tableCards[0].id,
          zq: this.tableCards[0].zq,
          zqLocation: this.zqs[0],
          dataPeriod: dataPeriod,
          selectvalueIndex: this.selectvalueIndex,
          version: this.versionRadio,
          user: this.userNum,
          // qykjzdIndex: this.qykjzdIndex,
        },
        query: {
          nsrsbhIndex: this.nsrsbhIndex,
          info: JSON.stringify(this.selectvalue),
          available: this.available ? 1 : 0,
          thirdDataSource: this.thirdDataSource,
          taxRateCalculation: this.taxRateCalculation,
          taxfirmTag: this.getTaxFirmEditionListValue,
          deleteDataType: this.deleteDataType,
        },
      });
    },

    getNewList() {
      this.getprojectList();
    },

    selectPro(val) {
      this.selectvalue = val.info;
      this.selectvalueIndex = val.projectIndex;
      this.nsrsbhIndex = val.info.nsrsbh;
      sessionStorage.setItem("nsrsbh", this.nsrsbhIndex);
    },
    getprojectList() {
      let params = {
        consumer: "",
        projectName: "",
        startTime: "",
        endTime: "",
        pageNo: 0,
        pageSize: 1000,
      };
      getProjectListNew(params, localStorage.getItem("authorization")).then((res) => {
        this.projectData = res.data.content;
        this.projectId = this.projectData.map((item) => {
          return item.id;
        });
      });
    },

    ...mapMutations("UploadTable", ["reset"]),

    async onYearPickerChanged(val) {
      const year = val;
      this.zqs = [];

      this.tableCards[0].zq = `${year}004`;
      this.zqs.push(`${year}004`);

      const secYear = year - 1;
      this.tableCards[1].zq = `${secYear}004`;
      this.zqs.push(`${secYear}004`);
      localStorage.setItem("zqY", this.yearPicker.value);
    },
  },
};
</script>

<style lang="stylus" scoped>
.page-wrapper {
  padding-bottom: 84px;
}

#status_upload {
  padding: 0;

  >>> .el-dialog__footer {
    text-align: center;

    .redfont {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(241, 58, 76, 1);
      margin: 38px 0 19px 0;
    }

    .el-button {
      border: none;
      background: #007AFF;
      font-size: 22px;
      font-family: Microsoft YaHei;
      padding: 18px 50px;
      margin-bottom: 30px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
    }
  }

  .headercss {
    >>> .el-tabs__header {
      .el-tabs__nav-wrap {
        .el-tabs__nav-scroll {
          .el-tabs__item {
            color: #7F9BC9;
            font-size: 18px;
          }

          .el-tabs__nav {
            left: 205px;

            .el-tabs__active-bar {
              height: 4px;
              background: #007AFF;
            }
          }

          .el-tabs__item.is-active {
            color: #062340;
            font-weight: bold;
          }
        }
      }
    }
  }

  >>> .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }

  >>> .status_upload1 {
    .el-tabs__header .el-tabs__nav {
      left: 0px !important;
    }

    .el-tabs__item {
      height: 60px;
      font-size: 14px !important;
      line-height: 60px;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .el-tabs__header.is-left {
      margin-right: 0;
      background: #eaedf7;
      font-size: 14px;
    }

    .el-tabs__item.is-left {
      text-align: left;
      padding: 0 20px;
      color: #7f9bc9;
    }

    .el-tabs__item.is-active {
      background: #fff;
      color: #007aff !important;
    }

    .el-tabs__active-bar {
      width: 0 !important;
    }

    .el-tabs__content {
    }

    .el-tabs__nav-wrap::after {
      content: none;
    }
  }
}

.table-container {
  max-height: 450px;
  overflow-y: auto;

  .table {
    width: 90%;
    margin: 0 auto;
    border-spacing: 0;

    tr {
      th {
        background: #EAEDF7;
        color: #7F9BC9;
        font-weight: normal;
        padding: 15px;
      }

      td {
        padding: 15px;
        border-left: 1px solid #EAEDF7;
      }

      td:last-child {
        border-right: 1px solid #EAEDF7;
      }
    }

    tr:nth-child(odd) {
      td {
        background: #F5F7FC;
      }
    }

    tr:last-child {
      td {
        border-bottom: 1px solid #F5F7FC;
      }
    }

    .statusup {
      text-align: center;
    }

    .statusup1 {
      color: #56D756;
      font-weight: bold;
    }
  }
}

.section-box {
  position: relative;
  padding-bottom: 10px;
  padding: 0;
}

.btn-test {
  font-size: 18px;
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  min-height: 500px;
  padding: 30px 0 0;
}

.icon-zq {
  position: absolute;
  left: -24px;
  top: 11px;
  color: #D9D9D9;
  font-size: 16px;
}

.pickers {
  display: flex;
  flex-direction: column;
  margin-left: 68px;
  // padding-bottom: 14px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);

  .item {
    margin: 12px 0;
    display: flex;
    align-items: center;
    position: relative;

    .icon-tip {
      position: absolute;
      top: -4px;
      left: -22px;
      cursor: pointer;
      padding: 4px;
    }
  }

  .label {
    display: inline-block;
    margin-right: 8px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
  }

  .labels:before {
    content: '*';
    color: red;
    position: relative;
    top: 2px;
    left: -2px;
  }

  .picker {
    // margin: 0 20px;
  }
}

.cards {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;

  .card {
    flex: auto 0 0%;
    width: 250px;
    margin: 30px auto;
    transition: 0.3s all;
  }

  .card:hover {
    transform: translateY(-5px);
  }

  .placeholder {
    width: 250px;
    height: 0px;
    margin: 0px auto;
  }
}

.progressdis {
  width: 90%;
  margin: 24px auto;
}

.picker >>> .el-input__inner {
  height: 36px;
  line-height: 36px;
  width: 325px;
}

>>> .el-input__icon {
  line-height: 36px;
}

.pickers .pickerDq {
  margin-left: -6px;
}

.pickers-flex {
  display: flex;
  align-items: center;
}

.pickers .pickers-flex-left {
  margin-right: 80px;
}

.title-box {
  padding: 24px 0 12px 24px;

  .fix-title-tips {
    font-size: 14px;
    color: #F5222D;
    margin-left: 16px;
  }
}

.title-content {
  // width: 1260px;
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
  position: relative;
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

>>>.bread-crumb-wrapper {
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

.page-title-wrapper {
  padding: 0;
  padding-left: 30px;
  line-height: 28px;
}

.new-next-btn {
  width: 360px;
  height: 46px;
  line-height: 46px;
  background: #2F54EB;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}

.new-next-btn:hover {
  background: #0f3bec;
  color: #fff;
}

.ease {
  transition: all 0.3s;
}

.form-bottom {
  display flex
  flex-direction row
  margin-left 188px
  padding-bottom: 16px;

  .form-bottom-item {
    margin-right 45px
    color rgba(0, 0, 0, 0.6000);
    .toc {
      color: #2f54eb;
      display inline-block
      cursor pointer
      font-size 14px
    }

    >>> .el-checkbox__input.is-checked + .el-checkbox__label {
      color: rgba(0,0,0,0.6) !important;
    }

    .text {
      color rgba(0, 0, 0, 0.6000);
      display inline
    }
  }

}
</style>
